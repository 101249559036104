/* ------------------------------------------------------------------------------
*
*  # Popovers component
*
*  Overrides for popovers bootstrap component
*
*  Version: 1.0
*  Latest update: May 25, 2015
*
* ---------------------------------------------------------------------------- */


// Base class
// -------------------------

// Base
.popover {
    border-radius: @border-radius-base;
    padding: 0;
    border-width: 0;
    .box-shadow(@shadow-depth2);

    // Hide arrow
    .arrow {
        display: none;
    }
}

// Title
.popover-title {
    font-size: @font-size-small;
    line-height: @line-height-small;
    border: 0;
    padding: @content-padding-base @content-padding-base 0 @content-padding-base;
    text-transform: uppercase;
    font-weight: 500;
    .border-top-radius(@border-radius-base);

    // Reduce size if inside colored container
    &[class*=bg-] {
        padding: @content-padding-small @content-padding-base;
        margin: -1px -1px 0 -1px;
    }
}

// Content
.popover-content {
    padding: @content-padding-base;
}
