/* ------------------------------------------------------------------------------
*
*  # Button group component
*
*  Overrides for button group bootstrap component
*
*  Version: 1.1
*  Latest update: Oct 20, 2015
*
* ---------------------------------------------------------------------------- */


// Base
// -------------------------

// Prevent double borders when buttons are next to each other
.btn-group,
.input-group-btn {
    .btn + .btn,
    .btn + .btn-group,
    .btn-group + .btn,
    .btn-group + .btn-group {
        margin-left: 1px;
    }
}

// Group multiple button groups together for a toolbar
.btn-toolbar {
    font-size: 0;
    
    .btn-group,
    .input-group {
        float: none;
    }
}

// Remove left border radius in multiple buttons
.btn-group > .btn-group:last-child:not(:first-child) > .btn:first-child {
    .border-left-radius(0);
}


// Sizing
// -------------------------

// Since we use overrides in buttons.less, sizing needs to be
// dublicated here
.btn-group-xlg > .btn {
    &:extend(.btn-xlg);
}
.btn-group-lg > .btn {
    &:extend(.btn-lg);
}
.btn-group-sm > .btn {
    &:extend(.btn-sm);
}
.btn-group-xs > .btn {
    &:extend(.btn-xs);
}


// Caret button size in split buttons
// ----------------------

// Default button and button group
.btn-group > .btn + .dropdown-toggle {
    padding-left: @padding-base-vertical;
    padding-right: @padding-base-vertical;
}

// XLarge button and button group
.btn-group > .btn-xlg + .dropdown-toggle,
.btn-group-xlg > .btn + .dropdown-toggle {
    padding-left: @padding-xlarge-vertical;
    padding-right: @padding-xlarge-vertical;
}

// Large button and button group
.btn-group > .btn-lg + .dropdown-toggle,
.btn-group-lg > .btn + .dropdown-toggle {
    padding-left: @padding-large-vertical;
    padding-right: @padding-large-vertical;
}

// Small button and button group
.btn-group > .btn-sm + .dropdown-toggle,
.btn-group-sm > .btn + .dropdown-toggle {
    padding-left: @padding-small-vertical;
    padding-right: @padding-small-vertical;
}

// Mini button and button group
.btn-group > .btn-xs + .dropdown-toggle,
.btn-group-xs > .btn + .dropdown-toggle {
    padding-left: @padding-xs-vertical;
    padding-right: @padding-xs-vertical;
}


// The clickable button for toggling the menu
// ----------------------

.btn-group.open .dropdown-toggle:not(.btn-flat):not(.btn-link),
.input-group-btn.open .dropdown-toggle:not(.btn-flat):not(.btn-link) {
    .box-shadow(0 0 0 100px fade(#000, 10%) inset);
}
