/* ------------------------------------------------------------------------------
 *
 *  # Glyphicons for Bootstrap
 *
 *  Glyphicons icon font path and style overrides
 *
 *  Version: 1.0
 *  Latest update: May 25, 2015
 *
 * ---------------------------------------------------------------------------- */


// Change paths
@font-face {
    font-family: 'Glyphicons Halflings';
    src: url('@{icon-font-path}@{icon-font-name}.eot');
    src: url('@{icon-font-path}@{icon-font-name}.eot?#iefix') format('embedded-opentype'),
         url('@{icon-font-path}@{icon-font-name}.woff2') format('woff2'),
         url('@{icon-font-path}@{icon-font-name}.woff') format('woff'),
         url('@{icon-font-path}@{icon-font-name}.ttf') format('truetype'),
         url('@{icon-font-path}@{icon-font-name}.svg#@{icon-font-svg-id}') format('svg');
}

// Set fixed icon size
.glyphicon {
    font-size: @icon-font-size;
    vertical-align: middle;
    top: -1px;
}
