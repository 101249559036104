/* ------------------------------------------------------------------------------
*
*  # Close button component
*
*  Overrides for close button bootstrap component
*
*  Version: 1.0
*  Latest update: May 25, 2015
*
* ---------------------------------------------------------------------------- */


// Base styles
.close {
	text-shadow: none;
	.opacity(.6);

	// Hover/focus states
	&:hover,
	&:focus {
		outline: 0;
		.opacity(1);
	}
}
