/* ------------------------------------------------------------------------------
*
*  # Tooltips component
*
*  Overrides for tooltips bootstrap component
*
*  Version: 1.0
*  Latest update: May 25, 2015
*
* ---------------------------------------------------------------------------- */


// Base class
.tooltip {
    font-size: @font-size-base;
    line-height: @line-height-base;

    // Hide arrow
    .tooltip-arrow {
        display: none;
    }

    // Custom color
    [class*=bg-] {
        border-radius: @border-radius-base;

        > .tooltip-inner {
            background-color: inherit;
        }
    }
}

// Wrapper for the tooltip content
.tooltip-inner {
    padding: @padding-base-vertical @padding-base-horizontal;
}
